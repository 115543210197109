/**Packages */
import { useState, useEffect, useContext } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Cookies from "universal-cookie";

/**Components */
import PageTitle from "../../../components/Elements/PageTitle";
import FullWidthLayout from "../../../components/Layout/FullWidthLayout";
import ContentBox from "../../../components/Elements/ContentBox";
import Row from "../../../components/Elements/Row";
import FlexRow from "../../../components/Elements/FlexRow";
import Form from "../../../components/Form/Form";
import FloatingInput from "../../../components/Form/FloatingInput";
import Radio from "../../../components/Form/Radio";

/**Helpers */
import { PAGES } from "../../../helpers/constants";
import { signupProcedure } from "../../../helpers/handler";
import { setError } from "../../../redux/actions/errorActions";

/**Redux */
import { LOADER_VISIBLE, LOADER_INVISIBLE } from "../../../redux/actionTypes/loaderActionsTypes";

/**CSS */
import "./style.css";
import NavigationProvider from "../../../providers/NavigationProvider";

const Registration = () => {
  // const { t, i18n, ready } = useTranslation();
  // const [formValidated, setFormValidated] = useState(false);

  const dispatch = useDispatch();
  const activateLoader = () => dispatch({ type: LOADER_VISIBLE });
  const deactivateLoader = () => dispatch({ type: LOADER_INVISIBLE });
  const setErrorState = (title, message) => dispatch(setError(title, message));

  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [userID, setUserID] = useState("");
  const [userCF, setUserCF] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userConfirmEmail, setUserConfirmEmail] = useState("");
  // const [userPassword, setUserPassword] = useState("");
  // const [userConfirmPassword, setUserConfirmPassword] = useState("");
  const [userCheckCode, setUserCheckCode] = useState("");
  const [userPrivacy, setUserPrivacy] = useState("");

  // useEffect(() => {
  //   console.log(userPrivacy);
  //   validateInput();
  // });

  // const validateInput = () => {
  //   let validated = true;
  //   if (
  //     name === "" ||
  //     surname === "" ||
  //     userID === "" ||
  //     userCF === "" ||
  //     userEmail === "" ||
  //     userConfirmEmail === "" ||
  //     userConfirmEmail !== userEmail ||
  //     // userPassword === "" ||
  //     // userConfirmPassword === "" ||
  //     // userConfirmPassword !== userPassword ||
  //     userCheckCode === ""
  //   ) {
  //     validated = false;
  //   }
  //   setFormValidated(validated);
  // };

  const nameChangeHandler = (event, value) => {
    setName(value);
  };

  const surnameChangeHandler = (event, value) => {
    setSurname(value);
  };

  const userIDChangeHandler = (event, value) => {
    setUserID(value);
  };

  const userCFChangeHandler = (event, value) => {
    setUserCF(value);
  };

  const userEmailChangeHandler = (event, value) => {
    setUserEmail(value);
  };

  const onUserPrivacy = (e) => {
    if (e === false) {
      setUserPrivacy(false);
    } else {
      const value = e.target.checked;
      setUserPrivacy(value);
    }
  };

  // const userPasswordChangeHandler = (event, value) => {
  //   setUserPassword(value);
  // };
  // const userConfirmPasswordChangeHandler = (event, value) => {
  //   setUserConfirmPassword(value);
  // };

  const userConfirmEmailChangeHandler = (event, value) => {
    setUserConfirmEmail(value);
  };

  const userCheckCodeChangeHandler = (event, value) => {
    setUserCheckCode(value);
  };

  const signupHandler = async (event) => {
    event.preventDefault();
    activateLoader();

    const onSuccessCallback = (data) => {
      deactivateLoader();
      NavigationProvider.goTo(PAGES.SIGNUP_CONFIRMATION, { email: userEmail });
    };

    const onErrorCallback = (error) => {
      deactivateLoader();
      setErrorState("Errore", error.description || "Registrazione non avvenuta, ritenta più tardi");
    };

    await signupProcedure(
      userID,
      userEmail,
      userCF,
      userCheckCode,
      // "003,100",
      // userPassword,
      userPrivacy,
      //bPrivacyCom,
      userPrivacy,
      onSuccessCallback,
      onErrorCallback
    );
  };

  return (
    <>
      <FullWidthLayout id="signup-page">
        <PageTitle small title="Registrazione">
          <p>Per registrarti segui la procedura guidata e compila il form.</p>
          <p>
            Dopo la registrazione ti sarà inviata una e-mail al tuo indirizzo di posta con facili istruzioni per
            completare la procedura e accedere all’area riservata.
          </p>
        </PageTitle>

        <Row gridSize="ms" classList="space-below-lg">
          <ContentBox compact withTitle="dati anagrafici" fullWidthOnMobile classList="text-center">
            <p>Compila i campi con i tuoi dati</p>

            {/* <Form autocomplete="off" submitEnabled={formValidated} submitText="Registrati" onSubmit={signupHandler}> */}
            <Form autocomplete="off" submitText="Registrati" onSubmit={signupHandler}>
              <FloatingInput
                name="userId"
                placeholder="Nome"
                validIf={["notEmpty"]}
                value={name}
                onChange={nameChangeHandler}
              />
              <FloatingInput
                name="surname"
                placeholder="Cognome"
                validIf={["notEmpty"]}
                value={surname}
                onChange={surnameChangeHandler}
              />
              <FloatingInput
                name="username"
                placeholder="Scegli un nome utente"
                validIf={["notEmpty"]}
                autocomplete={false}
                value={userID}
                onChange={userIDChangeHandler}
              />
              <FloatingInput
                name="cf"
                placeholder="Codice fiscale"
                validIf={["minLength--16", "maxLength--16", "notEmpty"]}
                value={userCF}
                onChange={userCFChangeHandler}
              />
              <FloatingInput
                name="email"
                placeholder="E-mail"
                validIf={["email", "notEmpty"]}
                value={userEmail}
                onChange={userEmailChangeHandler}
              />
              <FloatingInput
                name="emailConfirm"
                placeholder="Reinserisci l'email"
                validIf={[`equalTo--${userEmail}`]}
                value={userConfirmEmail}
                onChange={userConfirmEmailChangeHandler}
              />
              {/* <FloatingInput
                type="password"
                name="password"
                placeholder="Password"
                required
                autocomplete={false}
                value={userPassword}
                onChange={userPasswordChangeHandler}
              />
              <FloatingInput
                type="password"
                name="passwordConfirm"
                placeholder="Reinserisci la password"
                required
                value={userConfirmPassword}
                onChange={userConfirmPasswordChangeHandler}
              /> */}

              {/* <div id="captcha">captcha</div> */}

              <h3>Dati di riconoscimento</h3>
              <small>
                <i>Ultime 4 cifre del numero della carta/fido</i>
              </small>

              <FlexRow gridSize="md" classList="gap cols-equal">
                <div className="cpar-flex-col">xxxx</div>
                <div className="cpar-flex-col">xxxx</div>
                <div className="cpar-flex-col">xxxx</div>
                <div className="cpar-flex-col">
                  <FloatingInput
                    name="captchaCode"
                    placeholder=""
                    validIf={["minLength--4", "maxLength--4", "notEmpty"]}
                    hideError
                    value={userCheckCode}
                    onChange={userCheckCodeChangeHandler}
                  />
                </div>
              </FlexRow>

              <FlexRow gridSize="md" classList="responsive columns">
                <h3>consenso al trattamento dei dati</h3>
                <p>
                  <small>
                    Informativa ai sensi dell’art. 13 del Regolamento UE 2016/679 e della normativa nazionale vigente in
                    materia di protezione dei dati personali
                  </small>
                </p>
              </FlexRow>
              <FlexRow gridSize="md" classList="cols-equal responsive">
                <textarea readOnly name="" id="" rows="4">
                  Compass Banca S.p.A. (di seguito, “Compass Banca”) in Via Caldera, 21 – 20153 Milano, fornisce di
                  seguito l’informativa ai sensi dell’art.13 del Regolamento UE 2016/679 (di seguito anche “Regolamento”
                  o “GDPR”) e della normativa nazionale vigente in materia di protezione dei dati personali (di seguito,
                  unitamente al GDPR, “Normativa Privacy”), in qualità di ‘Titolare del trattamento’. Finalità del
                  trattamento I dati personali raccolti tramite il presente form elettronico vengono trattati da Compass
                  Banca per le seguenti finalità di: registrazione dell’utente per l’accesso all’area riservata del
                  sito, ove è possibile presentare la richiesta on line di prodotti e servizi di Compass Banca, seguire
                  l’iter di richieste presentate nonché monitorare alcuni aspetti dei prodotti e servizi richiesti. Il
                  conferimento dei dati richiesti nel presente form è obbligatorio, in quanto necessario per poter
                  procedere alla registrazione ed al successivo invio telematico delle credenziali per l’accesso. Il
                  mancato conferimento dei dati non consentirà di procedere alla registrazione richiesta. per ricerche
                  di mercato, per attività commerciali e promozionali relative a prodotti e servizi di Compass Banca
                  nonché di Società appartenenti al Gruppo Mediobanca. Le comunicazioni commerciali e promozionali
                  potranno essere veicolate mediante strumenti tradizionali (posta cartacea) e anche mediante l’uso di
                  tecniche di comunicazione a distanza quali telefono, anche senza operatore, posta elettronica, mms,
                  applicazioni informatiche (APP), area riservata, sms, altri servizi di messaggistica, fax, motori di
                  ricerca, piattaforme web, ovvero attraverso social network. Il conferimento di dati per questa
                  finalità è facoltativo e il trattamento richiede il suo consenso. In caso di rifiuto non ci saranno
                  conseguenze sulla richiesta registrazione al sito. Base giuridica La base giuridica del trattamento è
                  data dall’esecuzione di misure precontrattuali e contrattuali adottate su richiesta dell’interessato e
                  dal consenso espresso dell’interessato. Modalità del trattamento Il trattamento dei dati (con
                  esclusione della diffusione) avviene mediante strumenti manuali, informatici e telematici, con logiche
                  strettamente connesse alle finalità, ed è protetto da idonee misure di sicurezza. Categorie di
                  soggetti cui sono comunicati i dati I dati di cui sopra possono essere comunicati da Compass Banca ai
                  seguenti soggetti, anche esteri: i dati di cui al punto a): a società che svolgono per Compass compiti
                  di registrazione ed elaborazione dei dati e di supporto all’attività di registrazione all’area
                  riservata, nonché a cui è affidata l’esecuzione di attività correlate alla gestione dei rapporti
                  contrattuali con Compass Banca. Dette società operano in qualità di titolari autonomi o responsabili
                  del trattamento. I nominativi sono riportati negli elenchi costantemente aggiornati disponibili presso
                  le Filiali di Compass Banca o consultabili dal sito www.compass.it (per il solo elenco dei
                  Responsabili); i dati di cui al punto b): a società che svolgono attività commerciali e promozionali
                  per finalità di marketing, ivi compreso l’invio di materiale illustrativo relativo ai servizi e ai
                  prodotti commercializzati. Dette società operano in qualità di responsabili del trattamento esterno
                  dei dati. Il loro elenco costantemente aggiornato è disponibile presso le Filiali di Compass Banca o
                  consultabile dal sito www.compass.it. I dati personali trasferiti all’esterno dell'Unione europea, lo
                  saranno esclusivamente in presenza di una decisione di adeguatezza della Commissione Europea o di
                  altre garanzie adeguate previste dal GDPR (fra cui le norme vincolanti d'impresa, Scudo UE-USA e le
                  Clausole Contrattuali Tipo di Protezione). All'interno della nostra Azienda possono venire a
                  conoscenza dei dati personali in questione i dipendenti e i collaboratori da noi autorizzati al loro
                  trattamento, appartenenti a servizi ed uffici centrali e alla rete di vendita, preposti alla fornitura
                  delle informazioni suddette, all’organizzazione degli appuntamenti, ad attività commerciali e
                  promozionali, nonché all’esecuzione di attività correlate alla gestione dei rapporti contrattuali con
                  Compass Banca. Data retention Nel rispetto dei principi di proporzionalità e necessità, i dati
                  personali saranno conservati in una forma che consenta l’identificazione degli interessati per un arco
                  di tempo non superiore al conseguimento delle finalità per le quali gli stessi sono trattati, ossia
                  tenendo in considerazione: la necessità di continuare a conservare i dati personali raccolti per
                  offrire i servizi concordati con l’utente o per tutelare l’interesse legittimo del Titolare, così come
                  descritto nelle finalità sopraindicate, in ottemperanza alle valutazioni svolte da parte di Compass
                  Banca; l’esistenza di specifici obblighi normativi o contrattuali che rendono necessario il
                  trattamento e la conservazione dei dati per determinati periodi di tempo. Compass Banca adotta misure
                  ragionevoli per garantire che i dati personali inesatti siano rettificati o cancellati. Diritti
                  dell’interessato I soggetti cui si riferiscono i dati personali hanno il diritto, in qualunque
                  momento, di ottenere da Compass Banca la conferma dell'esistenza o meno dei medesimi dati e di
                  conoscerne il contenuto e l'origine, veri?carne l'esattezza o chiederne l'integrazione o
                  l'aggiornamento, oppure la retti?ca (artt. 15 e 16 del Regolamento). Inoltre, gli interessati hanno il
                  diritto di chiedere la cancellazione, la limitazione al trattamento, la revoca del consenso, la
                  portabilità dei dati nonché di proporre reclamo all’autorità di controllo e di opporsi in ogni caso,
                  per motivi legittimi, al loro trattamento (art. 17 e ss. del Regolamento). Tali diritti sono
                  esercitabili mediante comunicazione scritta da inviarsi a: info@compass.it. Il Titolare, anche tramite
                  le strutture designate, provvederà a prendere in carico la richiesta e a fornire, senza ingiusti?cato
                  ritardo, le informazioni relative all’azione intrapresa riguardo alla richiesta. Il Titolare
                  provvederà, anche tramite le strutture designate, a prendere in carico la richiesta e a fornire, senza
                  ingiustificato ritardo, le informazioni relative all’azione intrapresa riguardo alla richiesta.
                  Titolare del trattamento e Data Protection Officer Il Titolare del trattamento dei dati è Compass
                  Banca S.p.A. con sede in Via Caldera, 21 – 20153 Milano. Compass Banca ha designato un Responsabile
                  della protezione dei dati personali (c.d. Data Protection Officer). Il Data Protection Officer può
                  essere contattato ai seguenti indirizzi: dpo.mediobanca@mediobanca.com;
                  dpomediobanca@pec.mediobanca.com.
                </textarea>
              </FlexRow>

              <FlexRow gridSize="md" classList="cols-equal responsive">
                <p>
                  <small>
                    Preso atto dell’informativa resami da Compass Banca S.p.A. ai sensi dell’art. dell’art.13 del
                    Regolamento UE 2016/679 e della normativa nazionale vigente in materia di protezione dei dati
                    personali:
                  </small>
                </p>
              </FlexRow>

              <div className="inline-input-group">
                {/* <label htmlFor="accept-tos-yes">
                  <input
                    name="userPrivacy"
                    value="true"
                    onChange={(e) => {
                      setUserPrivacy(e.target.value);
                    }}
                    id="user-privacy-yes"
                    type="radio"
                  />{" "}
                  Presto il consenso
                </label> */}
                <Radio
                  name="userPrivacy"
                  value="true"
                  checked={userPrivacy == true}
                  onChange={(value) => {
                    onUserPrivacy(value);
                  }}
                  id="user-privacy-yes"
                  label="Presto il consenso"
                  // validIf={["radioChecked"]}
                />

                <label htmlFor="accept-tos-no">
                  <input
                    name="userPrivacy"
                    value="false"
                    id="user-privacy-no"
                    type="radio"
                    onChange={(value) => {
                      onUserPrivacy(false);
                    }}
                  />{" "}
                  Nego il consenso
                </label>
              </div>

              <FlexRow gridSize="md" classList="cols-equal responsive space-below-md">
                <p>
                  <small>
                    a che i miei dati personali siano trattati per la finalità di cui alla lettera b), da parte di
                    Compass Banca e di Società appartenenti al Gruppo Mediobanca (direttamente o per il tramite di
                    outsourcers specializzati), in relazione a prodotti e servizi propri e di terzi
                  </small>
                </p>
              </FlexRow>
            </Form>
          </ContentBox>
        </Row>
      </FullWidthLayout>
    </>
  );
};

export default Registration;
