/**Packages */
import Cookies from "universal-cookie";

/**Helpers */
import { TOKEN_KEY } from "./constants";
/**
 *
 * @returns token from the session Storage
 */
export const getToken = () => {
  //return sessionStorage.getItem(TOKEN_KEY);
  const cookies = new Cookies();
  return cookies.get(TOKEN_KEY);
};
/**
 *
 * @param {*} token - token value
 * The method sets value of the token in sessionStorage
 */
export const setToken = (token) => {
  //sessionStorage.setItem(TOKEN_KEY, token);
  const cookies = new Cookies();
  cookies.set(TOKEN_KEY, token, { path: "/", secure: true });
};
/**
 * The method removes token from the sessionStorage
 */
export const removeToken = () => {
  //sessionStorage.removeItem(TOKEN_KEY);
  const cookies = new Cookies();
  cookies.remove(TOKEN_KEY, { path: "/" });
};
